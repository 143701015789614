export default {
    name : "XposeFTC Manager",//le nom de l'application,
    description : "Logiciel efficace et simplifié de gestion commerciale",
    version : "1.0.0",
    /*** l'environnement d déploiement de l'apps 
     *  production | development | test
    */
    env : "production",
    realeaseDateStr : "1er Juin 2021",
    releaseDate : "2020-05-23",
    devMail : "saliteapp@gmail.com",
    devWebsite : "http://fto-consulting.com/salite-config/",
    //copyRight : "Boris F, Dir Info FirsTo Consulting@Jan 2020",
    copyRight : "firsto consulting@Jan 2020",
    author : 'firsto consulting',
    id : "com.ftc.apps.xpose-manager-front",//l'unique id de l'application,
    /****la version de l'api next côté serveur actuellement déployée */
    apiVersion : "", 
    theme : {
        light : {
            primary : "#3D8B5F",
            secondary : "#354448",
        },
        dark : {

        }
    },
}